/* eslint-disable n/no-process-env */
import PropTypes from 'prop-types';
import { useCapture } from './useCapture';

export const UseCaptureWrapper = ({ upload, inputCaptureType, children }) => {
  const capture = useCapture(upload, inputCaptureType);

  return (
    <>
      {/* It will be inserted when running unit test */}
      {/* For security reasons it was removed from DOM */}
      {process.env.NODE_ENV === 'test' && (
        <input
          type="file"
          name="image"
          accept="image/*"
          capture={inputCaptureType}
          onChange={upload}
          style={{ opacity: 0, zIindex: 99 }}
          data-testid="capture-base-upload-doc-hidden-input"
          aria-hidden="true"
        />
      )}
      {children({ capture })}
    </>
  );
};

UseCaptureWrapper.propTypes = {
  upload: PropTypes.func.isRequired,
  inputCaptureType: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['user', 'environment'])]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};
