import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { OnlyMobileAllowed, QRVerificationPage, SendSMS } from '@containers';
import { LoadingSpinner } from '@FLOW_V2_FLOW/components';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';
import { ALLOW_MOBILE_EMULATORS, NON_MOBILE_DEVICE_FALLBACK } from '@spotMobileConfig';
import { NON_MOBILE_DEVICE_SCREEN_TYPES } from '@lib/constants/nonMobileDeviceScreenTypes';

export const DEVICE_MOTION_DETECTION_STATE = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  DENIED: 'DENIED',
  FAILED: 'FAILED'
};

const dataHistory = [];
const MAX_DATA_READINGS_SAVED = 20;

const COUNT_MOTION_THRESHOLD_TO_BE_CONSIDERED_REAL_DEVICE = 5;
const DELTA_MOTION_THRESHOLD_BETWEEN_MOVEMENTS = 0.001;

const DELTA_THRESHOLD_BETWEEN_ROTATION = 10;
const COUNT_ROTATION_THRESHOLD_TO_BE_CONSIDERED_UNREAL_DEVICE = 10;

const evaluateRotationChanges = (dataHistory) => {
  const firstReads = dataHistory[0];
  const { alpha, beta, gamma } = firstReads;
  if (alpha === 0 && beta === 0 && gamma === 0) {
    return false;
  }

  let unlikelyRotationCount = 0;

  for (let i = 1; i < dataHistory.length; i++) {
    const { alpha: alpha1, beta: beta1, gamma: gamma1 } = dataHistory[i];
    const { alpha: alpha2, beta: beta2, gamma: gamma2 } = dataHistory[i - 1];
    const deltaAlpha = Math.abs(alpha1 - alpha2);
    const deltaBeta = Math.abs(beta1 - beta2);
    const deltaGamma = Math.abs(gamma1 - gamma2);

    if (
      deltaAlpha > DELTA_THRESHOLD_BETWEEN_ROTATION ||
      deltaBeta > DELTA_THRESHOLD_BETWEEN_ROTATION ||
      deltaGamma > DELTA_THRESHOLD_BETWEEN_ROTATION
    ) {
      ++unlikelyRotationCount;
    }
  }

  return !(unlikelyRotationCount >= COUNT_ROTATION_THRESHOLD_TO_BE_CONSIDERED_UNREAL_DEVICE);
};

const evaluateMotionChanges = (dataHistory) => {
  let significantChangeCountX = 0;
  let significantChangeCountY = 0;
  let significantChangeCountZ = 0;

  for (let i = 1; i < dataHistory.length; i++) {
    const { x: x1, y: y1, z: z1 } = dataHistory[i];
    const { x: x2, y: y2, z: z2 } = dataHistory[i - 1];
    const deltaX = Math.abs(x1 - x2);
    const deltaY = Math.abs(y1 - y2);
    const deltaZ = Math.abs(z1 - z2);

    if (deltaX > DELTA_MOTION_THRESHOLD_BETWEEN_MOVEMENTS) {
      significantChangeCountX++;
    }
    if (deltaY > DELTA_MOTION_THRESHOLD_BETWEEN_MOVEMENTS) {
      significantChangeCountY++;
    }
    if (deltaZ > DELTA_MOTION_THRESHOLD_BETWEEN_MOVEMENTS) {
      significantChangeCountZ++;
    }
  }

  const xHasChanged = significantChangeCountX > COUNT_MOTION_THRESHOLD_TO_BE_CONSIDERED_REAL_DEVICE;
  const yHasChanged = significantChangeCountY > COUNT_MOTION_THRESHOLD_TO_BE_CONSIDERED_REAL_DEVICE;
  const zHasChanged = significantChangeCountZ > COUNT_MOTION_THRESHOLD_TO_BE_CONSIDERED_REAL_DEVICE;

  return Number(xHasChanged) + Number(yHasChanged) + Number(zHasChanged) >= 2;
};

const useDeviceMotionDetection = () => {
  const [deviceMotionStatus, setDeviceMotionStatus] = useState(DEVICE_MOTION_DETECTION_STATE.IDLE);

  const onDeviceMotionEvent = useCallback(
    (event) => {
      const { x, y, z } = event.accelerationIncludingGravity;
      const { alpha, beta, gamma } = event.rotationRate;
      dataHistory.push({ x, y, z, alpha, beta, gamma });
      if (
        deviceMotionStatus !== DEVICE_MOTION_DETECTION_STATE.IDLE &&
        deviceMotionStatus !== DEVICE_MOTION_DETECTION_STATE.LOADING
      ) {
        window.removeEventListener('devicemotion', onDeviceMotionEvent);
      } else if (dataHistory.length > MAX_DATA_READINGS_SAVED) {
        dataHistory.unshift(); // Keep history limited to the last MAX_MOTION_READINGS_SAVED readings
        const isRealDeviceBasedOnMotion = evaluateMotionChanges(dataHistory);
        const isRealDeviceBasedOnrotation = evaluateRotationChanges(dataHistory);

        if (isRealDeviceBasedOnMotion && isRealDeviceBasedOnrotation) {
          setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.SUCCESS);
        } else {
          setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.FAILED);
        }
      }
    },
    [deviceMotionStatus]
  );

  useEffect(() => {
    window.addEventListener('devicemotion', onDeviceMotionEvent);

    return () => window.removeEventListener('devicemotion', onDeviceMotionEvent);
  }, [onDeviceMotionEvent]);

  const checkDeviceMotion = () => {
    if (deviceMotionStatus !== DEVICE_MOTION_DETECTION_STATE.IDLE) {
      return;
    }

    const MAX_DELAY_DETECTING_MOTION_IN_MS = 10000;
    if (
      typeof DeviceOrientationEvent !== 'undefined' &&
      typeof DeviceOrientationEvent.requestPermission === 'function'
    ) {
      setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.LOADING);

      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === 'granted') {
            setTimeout(function failIfNoMotionDetectedAfterTimeout() {
              console.error('Timeout device motion');
              setDeviceMotionStatus((currentStatus) => {
                if (
                  currentStatus === DEVICE_MOTION_DETECTION_STATE.IDLE ||
                  currentStatus === DEVICE_MOTION_DETECTION_STATE.LOADING
                ) {
                  return DEVICE_MOTION_DETECTION_STATE.FAILED;
                }
                return currentStatus;
              });
            }, MAX_DELAY_DETECTING_MOTION_IN_MS);
          } else if (permissionState === 'denied') {
            setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.DENIED);
          } else {
            console.warn({ permissionState });
            throw new Error('DeviceOrientationEvent access failed');
          }
        })
        .catch((err) => {
          console.error(err);
          setDeviceMotionStatus(DEVICE_MOTION_DETECTION_STATE.FAILED);
        });
    }
  };

  return { deviceMotionStatus, checkDeviceMotion };
};

const DeviceMotionDetectionContext = React.createContext({});

export const withDeviceMotionDetectionProvider = (OriginalComponent) => {
  function WithDeviceMotionProvider(props) {
    return (
      <DeviceMotionDetectionProvider>
        <OriginalComponent {...props} />
      </DeviceMotionDetectionProvider>
    );
  }

  return WithDeviceMotionProvider;
};

export const DeviceMotionDetectionProvider = ({ children }) => {
  const { deviceMotionStatus, checkDeviceMotion } = useDeviceMotionDetection();

  const value = useMemo(() => {
    return { deviceMotionStatus, checkDeviceMotion };
  }, [deviceMotionStatus, checkDeviceMotion]);

  return (
    <DeviceMotionDetectionContext.Provider value={value}>
      {children}
    </DeviceMotionDetectionContext.Provider>
  );
};

DeviceMotionDetectionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useDeviceMotionDetectionContext = () => {
  const { deviceMotionStatus, checkDeviceMotion } = useContext(DeviceMotionDetectionContext);

  if (deviceMotionStatus === undefined) {
    throw new Error(
      'deviceMotionStatus is undefined. Make sure useDeviceMotionDetectionContext is used within a DeviceMotionDetectionContext'
    );
  }

  return { deviceMotionStatus, checkDeviceMotion };
};

export const withDeviceMotionDetection = (OriginalComponent) => {
  function NoMotionDetectionWrapper(props) {
    return <OriginalComponent {...props} />;
  }

  function MotionDetectionWrapper(props) {
    const { deviceMotionStatus, checkDeviceMotion } = useDeviceMotionDetectionContext();

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.IDLE) {
      return (
        <Message
          buttons={[
            {
              label: localizedString('proceed'),
              onClick: () => {
                checkDeviceMotion();
              }
            }
          ]}
          title={localizedString('app.FLOW_V2_DEVICE_MOTION_ACCESS_REQUEST_TITLE')}
        >
          {parse(localizedString('app.FLOW_V2_DEVICE_MOTION_ACCESS_REQUEST_DESCRIPTION'))}
        </Message>
      );
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.LOADING) {
      return <LoadingSpinner heading={localizedString('loading')} />;
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.FAILED) {
      if (NON_MOBILE_DEVICE_SCREEN_TYPES.SMS === NON_MOBILE_DEVICE_FALLBACK) {
        return <SendSMS />;
      }

      if (NON_MOBILE_DEVICE_SCREEN_TYPES.QR === NON_MOBILE_DEVICE_FALLBACK) {
        return <QRVerificationPage />;
      }

      return <OnlyMobileAllowed />;
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.SUCCESS) {
      return <OriginalComponent {...props} />;
    }

    if (deviceMotionStatus === DEVICE_MOTION_DETECTION_STATE.DENIED) {
      return (
        <Message
          issue
          buttons={[
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                window.location.reload();
              }
            }
          ]}
          title={localizedString('app.FLOW_V2_DEVICE_MOTION_ACCESS_REQUEST_TITLE')}
        >
          {parse(localizedString('app.FLOW_V2_DEVICE_MOTION_ACCESS_DENIED_DESCRIPTION'))}
        </Message>
      );
    }
  }

  if (ALLOW_MOBILE_EMULATORS) {
    return NoMotionDetectionWrapper;
  }

  return MotionDetectionWrapper;
};
