import React, { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { Checkbox } from '@lib/components/v2/Form';
import { getFields } from '@lib/utils/v2/getFields';
import { calculateEditedInfo } from '@lib/utils/editedInfo';
import { isLocalizedStringDefined, localizedString } from '@languages';
import EditedAction from '@store/actions/edited';
import {
  FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2,
  FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME,
  FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT
} from '@spotMobileConfig';

import parse from 'html-react-parser';
import { Fields } from '@lib/components/v2/Contents/VerifyDetails/Fields';
import { useFullNameContext } from '@lib/components/v2/App/FullNameReviewProvider';
import classes from './VerifyDetails.style.module.scss';
import { getValidations } from './verifyDetailsValidations';

const getEmptyNameCheckboxConfig = (id, fields) => {
  const confirmNameIsEmptyFields = {
    firstName: {
      id: 'confirmFirstNameIsEmpty',
      label: localizedString('noFirstname')
    },
    middleName: {
      id: 'confirmMiddleNameIsEmpty',
      label: localizedString('noMiddlename')
    },
    lastName: {
      id: 'confirmLastNameIsEmpty',
      label: localizedString('noSurname')
    }
  };

  if (!FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
    return null;
  }

  if (!['firstName', 'middleName', 'lastName'].includes(id)) {
    return null;
  }

  if (fields[id] !== '') {
    return null;
  }

  return confirmNameIsEmptyFields[id];
};

const VerifyDetails = memo(
  ({
    idType,
    cardType,
    countryCode: country,
    countryOfIssue,
    onChange,
    address,
    addressNative,
    checkConfirm,
    addressData,
    engine4Config,
    useIdNumber,
    personNameFieldsOrder,
    isOldTurkishDL,
    isSubmiting,
    disabledFields = [],
    ...restProps
  }) => {
    const formStateRef = useRef();
    const dispatch = useDispatch();
    const extracted = useSelector(({ information }) => information?.extracted);
    const hasAddressBeenAlreadyChanged = useSelector(({ edited }) =>
      edited.fields.some((editedField) => editedField === 'addressData')
    );

    const { shouldDisplayFullName } = useFullNameContext();

    const fieldEditLimitCount = useSelector(({ appConfig }) => appConfig?.field_edit_limit_count);

    const fields = getFields({
      idType,
      countryOfIssue,
      addressData,
      useIdNumber,
      personNameFieldsOrder,
      shouldDisplayFullName,
      disabledFields,
      isOldTurkishDL,
      values: restProps,
      flowType: 'FLOW_V2'
    });

    const formValidations = getValidations({
      idType,
      countryOfIssue,
      values: restProps,
      flowType: 'FLOW_V2',
      cardType
    });

    const handleChange = ({ id, value, limitEdit = false } = {}) => {
      const formState = formStateRef.current || {};

      const { changedFields, editedCount } = calculateEditedInfo({
        extracted,
        fields: { ...formState.fields, [id]: value },
        dirty: formState.dirty,
        hasAddressBeenAlreadyChanged
      });

      if (limitEdit && editedCount > fieldEditLimitCount) {
        formState?.setField?.({ tooManyFieldsEdited: true });
        return;
      }

      dispatch(EditedAction.setEditedFields(changedFields));
      onChange(id, value);
      formState?.setField?.({ tooManyFieldsEdited: false });
    };

    return (
      <div className={classes.wrapper}>
        <Title
          data-testid="details-heading"
          title={localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_HEADING')}
        />
        {isLocalizedStringDefined('verifyDetails.FLOW_V2_VERIFY_DETAILS_DESCRIPTION') && (
          <div className={classes.description}>
            {parse(localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_DESCRIPTION'))}
          </div>
        )}
        <div className={classes.container} aria-live="polite">
          <Fields
            classes={classes}
            formValidations={formValidations}
            handleChange={handleChange}
            isSubmiting={isSubmiting}
            country={FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 || country}
            availableManualCountryCodesIso3={engine4Config?.acceptedCountries || null}
            address={address}
            addressNative={addressNative}
            addressData={addressData}
            getEmptyNameCheckboxConfig={getEmptyNameCheckboxConfig}
            fields={fields}
          />
          <Validation
            config={{
              tooManyFieldsEdited: {
                isEqual: {
                  message: localizedString('verifyDetails.FLOW_V2_EDIT_TOO_MANY_FIELDS_TITLE'),
                  value: false
                }
              }
            }}
            initialValues={{ tooManyFieldsEdited: false }}
          >
            {(formState) => {
              formStateRef.current = formState;
              return <div />;
            }}
          </Validation>
          {FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && (
            <Checkbox
              className={classNames(classes['check-confirm'])}
              id="confirm"
              value={checkConfirm}
              onChange={(value) => onChange('checkConfirm', value)}
              label={localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_CONSENT')}
              dataTestId="details-checkbox"
              labelDataTestId="details-consentTxt"
              disabled={isSubmiting}
            />
          )}
        </div>
      </div>
    );
  }
);

VerifyDetails.propTypes = {
  idType: PropTypes.string,
  cardType: PropTypes.string,
  onChange: PropTypes.func,
  /* Form data */
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  expiryDate: PropTypes.string,
  licenceNumber: PropTypes.string,
  licenceVersion: PropTypes.string,
  passportNumber: PropTypes.string,
  /* Confirmation */
  checkConfirm: PropTypes.bool,
  checklicenceNumber: PropTypes.bool,
  checklicenceVersion: PropTypes.bool,
  checkpassportNumber: PropTypes.bool,
  engine4Config: PropTypes.object,

  countryCode: PropTypes.string,
  countryOfIssue: PropTypes.string,
  addressData: PropTypes.object,
  useIdNumber: PropTypes.bool,
  personNameFieldsOrder: PropTypes.arrayOf(PropTypes.string),
  address: PropTypes.any,
  addressNative: PropTypes.string,
  isOldTurkishDL: PropTypes.bool,
  isSubmiting: PropTypes.bool,
  disabledFields: PropTypes.array
};

VerifyDetails.defaultProps = {
  idType: 'NZL_DRIVERLICENCE',
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  expiryDate: '',
  passportNumber: '',
  licenceNumber: '',
  licenceVersion: '',
  onChange: () => {},
  engine4Config: {},
  isOldTurkishDL: false,
  isSubmiting: false
};

export default VerifyDetails;
