import { useCallback, useRef } from 'react';

/**
 * @param {Event} upload - Callback called when input has changed image/file.
 * @param { boolean | "user" | "environment" | undefined} [inputCaptureType] - html input capture mechanism.
 * @returns {() => void}  Function ref that triguer capture.
 */
export const useCapture = (upload, inputCaptureType) => {
  const inputRef = useRef();

  const capture = useCallback(() => {
    const inputFile = document.createElement('input');
    inputFile.type = 'file';
    inputFile.accept = 'image/*';

    if (inputCaptureType) {
      inputFile.capture = inputCaptureType;
    }

    inputFile.addEventListener('change', upload);

    inputFile.click();

    // It keeps the reference alive preventing garbage collector destroy inputfile and therefore its listener
    inputRef.current = inputFile;
  }, [upload, inputCaptureType]);

  return capture;
};
